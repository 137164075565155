import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
// import Logo from "../components/logo"
import Logo from "../images/logo.png"

import { Container, Grid, Box, Typography, Button } from '@material-ui/core';

import styled from "styled-components";
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
let theme = createMuiTheme();
theme = responsiveFontSizes(theme);
const Bline = styled.hr`
border-bottom: 1px solid #fff;
`

const IndexPage = () => (
  <Layout>
    <SEO title="Private Equity and Investment Management" description="Apollonia Ventures is a team of data centre site selection and acquisition experts and seasoned professionals from private equity and investment banking" />
    <ThemeProvider theme={theme}>
   <Container>

<Box className="centercontent">

     <Grid container alignItems="center" justify="center">
       <Grid item xs={12} sm={10} md={9} style={{ color:'#fff' }}>

     <Grid container alignItems="center" justify="center">
     <Grid item xs={12} sm={6} md={5} style={{ color:'#fff' }}>
         <Box  pb={2}>
       {/* <Logo/>  */}

       <img src={Logo} alt="Apollonia Ventures" style={{ width:'100%', height:'auto' }} />
        </Box>
        <Box pb={2}>
        <Bline/>
        <Typography variant="body2" align="center" gutterBottom>
        Palo Alto • Geneva • Seoul • Singapore • Hong Kong
        </Typography>
        <Bline/>
        </Box>
        </Grid>
        </Grid>

        
        

<Typography variant="body2" align="center" paragraph>
Apollonia Ventures is a team of data centre site selection and acquisition experts and seasoned professionals from private equity and investment banking. Its data centre fund is architectured to 
make investments in development and acquisition of data centres and other technology and connectivity related real estates across the globe seeking  <b> 'α' </b>
</Typography>
<Typography variant="body2" align="center" paragraph>
This website is intended solely for the members and the investors of the team.  
For more information, please contact us at info@apolloniaventures.com
  </Typography>

<Box align="center" pt={2}>
 <Link to="/login/"> <Button variant="contained" color="secondary" style={{ backgroundColor:'transparent', border:'2px solid #fff', marginRight:7, fontSize:12 }}> investor login </Button> </Link>
 <Link to="/stafflogin/"> <Button variant="contained" color="secondary" style={{ backgroundColor:'transparent', border:'2px solid #fff', fontSize:12 }}> staff login </Button> </Link>
  </Box>
       </Grid>
     </Grid>

     </Box>
   </Container>

    </ThemeProvider>
  </Layout>
)

export default IndexPage
